
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.cheap_invoice {
	margin-top: 10px;
	a {
		font-family: $hf;
		padding: 0;
		background:none;
		border:0;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		text-transform: uppercase;
		vertical-align: middle;
		text-decoration: none;
		svg {
			fill: $gr3;
			margin-right: 5px;
			float: left;
			margin-top: 0px;
		}
		&:hover {
			color: $rd;
			svg {
			fill: $rd;
			}
		}
	}
}

.product-block {
	position: relative;
	.description {
		display: none;
	}
	.cart {
		height: 30px;
	}
	.product-meta {
		line-height: 25px;
		padding: 0 0 20px 0;
	}
	.bottom {
		display: block;
		button {
			padding: 0;
			border: 0;
		}
		a {
			padding: 0;
			border: 0;
		}
		.add {
			padding: 0;
			border: 0;
			color: $rd2;
			font-weight: bold;
font-size: 16px;
line-height: 24px;
			span {
				float: left;
				padding-right: 4px;
				path {
						fill: $rd2;
					}
				.title {margin-top:2px;}
			}
			&:hover {
				color: $rd;	
				path {
						fill: $rd;
					}
			}
		}
		.compare {
			display: inline-block;
			button {
				path {
						fill: #999;
					}
				&:hover {
					path {
						fill: $dg;
					}
				}
			}
		}
		.quickview {
			display: inline-block;
			padding-right: 8px;
			a {
				path {
						fill: #999;
					}
				&:hover {
					path {
						fill: $dg;
					}
				}
			}
		}
	}
	.name {
		margin: 10px 0;
		font-size: 14px;
		line-height: 18px;
		a {
			color: $black;
			&:hover {
				color: $rd2;
				text-decoration: none;
			}
		}
	}
	.price {
		font-size: 18px;
		line-height: 24px;
		font-family: $hf;
		font-weight: bold;
		float: none;
		display: block;
		overflow: hidden;
		.price-new {
			float: left;
		}
		.price-old {
			float: left;
			font-size: 14px;
			line-height: 24px;
			color: #999999;
			font-weight: normal;
			font-family: $f;
			padding: 0 0px 0px 10px;
		}
	}
	.block-img {
		position: relative;
	}
	.image {

		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		img {
			margin: 16px 0 0 0;
		}
	}
	.top {
		position: relative;
		padding-bottom: 25px;
	}
	.rating {
		color: #25B92F;
		height: 25px; 

	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	/*     z-index: 99; */
	.icon-cart {
		background: url(#{$image-theme-path-base}icon-shopping.png) no-repeat scroll center center;
		display: block;
		@include size(16px, 17px);
	}
}

.over {
	display: block;
	 -moz-box-sizing: border-box; /* Для Firefox */  
    box-sizing: border-box; /* Ширина блока с полями */
    overflow:hidden;
    padding: 0 15px;
    margin: 0 -15px;
}

.more_products, .product-set-btn {
	border: 1px solid $gr;
	font-weight: bold;
	font-size: 24px;
	line-height: 24px;
	padding: 32px 0;
	display: block;
	cursor: pointer;
	text-transform: uppercase;
	font-family: $hf;
	color: #999;
	margin: -1px -15px 0 -15px;
	text-decoration: none;
	&:hover {
		color: $rd2;
		text-decoration: none;
	}
}

.scroll-button {
	margin-top: 20px;
	float: right;
}

.inner_block {
	border: 1px solid $gr;
	
}

.tabs_product_module {
	.inner_block {
	
	&.p0 {
		padding: 0 0;
		border-right: 0;
	}
	&:nth-child(2) {
		border-left: 0;
	}
}
}

.product-label {
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	position: absolute;
	z-index: 1;
	left: 0;
	top:24px;

	&.sale, &.new {
		bottom: 0;
		top: unset;
	}
	&.new + &.sale {
		bottom: 27px;
	}
	&.naks {
		top: unset;
		bottom: 0;
		right: 0;
		left: unset;
		img {
			width: 100px;
		}
	}
	&.markdown-product {
		font-size: 14px;
		line-height: 14px;
		padding: 4px;
		background: $rd2;

		span {
			color: $wh;

		}
	}
	&.prepare-sale, &.free-delivery {
		font-size: 10px;
		line-height: 12px;
		padding: 4px;
		background: $wh;
		border: 2px solid $lb;
		@include rounded-corners(4px);
		white-space: nowrap;
	}
	&.free-delivery {
		left: 0;
	}
	&.prepare-sale {
		top: 50px;
		left: 0;
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.price-old {
	text-decoration: line-through;
	color: #666;
}

.func_list {
	display: flex;
	ol {
		font-size: 18px;
	}
	img {
		max-height: 400px;
	}
}

#storage_address {
	font-family: $hf;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 24px;
}

#sbscroll {
	max-height: 400px;
	flex: 0 1 500px;
}

.param_list {
	.double, .single {
		background: url(#{$image-theme-path-base}line.png) repeat-x left 18px;
		margin: 0 0 15px 0;
		overflow: hidden;
		& > span {
			background: $wh;
		}
		.param_k {
			color: $gr3;
			max-width: 68%;
			word-wrap: break-word!important;
			float: left;
		}
		.param_v {
			float: right;
			width: calc(30% - 5px);
			text-align: left;
			padding-left: 5px;
		}
	}
}

#otziv_block {
	.stars {
		color:$gn;
		font-size: 24px;
line-height: 28px;
		margin-bottom: 15px;
		.number {
			font-family: $hf;
font-weight: bold;
color: $bk;
		}
	}
	.rating_text {

	}
}

#review {
	table {
		border-top:1px solid $gr4; 
		td {
			border:0;
			.fa {
				color: $gn;
				font-size: 14px;
				width: 15px;
			}
			.author {
				float: left;
			}
			.stars {
				float: left;
				margin-left: 10px;
			}
			
		}
		.date-review, .author {
				font-family:  $hf;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
			}
	}
}

.cheap_invoice:first-child {
	margin-bottom: 15px;
}

.storage_widget {
	margin-top: 40px;
	border: 0!important;
	padding: 10px 0 10px 90px;
	font-size: 16px;
line-height: 24px;
	background: url(#{$image-theme-path-base}reserv.svg) no-repeat left center;
	& > span:nth-child(2) {
		color: $lb !important;
		@include transition(all .2s ease-in-out);
	}
	#storage_time {
		color: $bk!important;
	}
	table {
		margin: 25px 0 15px 0;

		tr {
		display: block;
		td {
			display: inline-block;
			&:first-child {
				span {
					border: 0 !important;
					border-radius: 50% !important;
					display: inline-block;
					height: 10px;
					width: 10px;
					background: #a5a5a5 !important;
					vertical-align: -1px;
					display: none!important;
				}
			}
			&:nth-child(2) {
				padding-left: 0 !important;
			}
			&:last-child span {
				background: $wh !important;
				text-transform: uppercase;
				border-radius: 3px!important;
    font-family: $hf!important;
font-style: normal!important;
font-weight: bold!important;
font-size: 16px!important;
line-height: 24px!important;
padding: 8px 16px!important;
height: auto!important;
color: $rd2 !important;
border: 1px solid $rd2 !important;
		margin-left: 24px;

				@include transition(all .2s ease-in-out);
				&:hover {
					background: $rd2 !important;
					color: $wh !important;
				}
			}
		}
	}

	}
}
.tabs {
		border: 1px solid $gr;
		margin-top: 50px;
		font-size: 16px;
line-height: 24px;
		.tabs__caption {
			margin: 0 0 25px 0;
			padding: 0;
			width: 100%;
			background: $gr;
			display: block;
			overflow: hidden;
			li {
				list-style: none;
				float: left;
				font-family:$hf;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				padding: 20px 30px;
				text-transform: uppercase;
				cursor: pointer;
			&.active {
				color: $rd2;
				background: $wh;
			}
			}
		}
		.tabs__content {
		  display: none;
		  padding: 0 30px 30px 30px;
		  @include transition(all .2s ease-in-out);
		  opacity: 0;
		  &.active {
		  	opacity: 1;
		  	display: block;
		  }
		  .dop_opis {
		  	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
		  	padding: 25px;
		  	margin-bottom: 25px;
		  	ul {
		  		padding: 0 0 0 20px;
		  		li {
		  			list-style-type: disc;
		  			a {
		  				text-decoration: none;
		  				&:hover {
		  					color: $rd;
		  				}
		  			}
		  		}
		  	}
		  }
		  .desc-header {
		  	font-family:$hf;
font-weight: bold;
font-size: 18px;
line-height: 24px;
			margin: 0 0 15px 0;
		  }
		  #tab-downloads {
		  	ul {
		  		padding: 0;
		  		li a {
		  			padding-left: 30px;
		  			background: url(#{$image-theme-path-base}download.svg) no-repeat left 4px;
		  			display: block;
		  			text-decoration: none;
		  			&:hover {
		  				color: $rd;
		  			}
		  		}
		  	}
		  }
		}
}
#review_btn {
	margin:25px 0;
}

.tabs_product_module {
	margin-top:50px;
	&.home_bestseller {
		margin-top: 0;
	}
	&.home_svar {
		margin-top: 0;
	}
	&.home_stab {
		margin-top: 0;
	}
}

#products {
	border: 1px solid $gr;
	&.pag-cont {
		border-bottom: 0;
		margin-bottom: -1px;
	}
}

#kupit_mob {
	position: fixed;
	width: 100%;
	bottom:0;
	left:0;
	background: #fff;
	padding: 20px 24px;
	z-index: 99;
	button {
		font-family: $hf;
		border: 0;
		background: $rd2;
		width: 100%;
		font-weight: bold;
font-size: 24px;
line-height: 28px;
		color: $wh;
		text-align: center;
		text-transform: uppercase;
		padding: 17px 0;
		.price-old {
			font-size: 18px;
			color: $gr;
		}
	}
}