//
// Navs
// --------------------------------------------------
$hot-color: #ff9800 !default;
.badges {
	&.new, &.hot {
		color: $white;
		font-size: $font-size-base - 3;
		height: 15px;
		line-height: 16px;
		padding: 0 5px;
		position: absolute;
		left: 20px;
		@include rounded-corners(2px);
		text-align: center;
		text-transform: uppercase;
		top: -5px;
		transform: translateZ(0px);
		font-family: $font-family-base;
		font-weight: 500;
		&:before {
			border-style: solid;
			border-width: 2px;
			bottom: -5px;
			content: "";
			left: 4px;
			position: absolute;
		}
	}

	&.new {
		background-color: $brand-info;
		&:before {
			border-color: $brand-info transparent transparent;
		}
	}

	&.hot {
		background-color: $hot-color;
		&:before {
			border-color: $hot-color transparent transparent;
		}
	}

}

.red {
	.menu-title {
		color: $red;
	}
}

.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		font-family: $hf;
		> li {
			position: inherit;
			height: 72px;
			&.aligned-left {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
				width: 834px;
				box-shadow: none;
			}
			&:hover,
			&:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
				> a {
					&.dropdown-toggle {
					background: $rd;
					color: $wh;
				}
				}
			}
			
			> a {
				padding: 24px;
				font-size: 18px;
				color: $dg;
				font-weight: bold;
				@include transition(all 200ms ease-out);
				line-height: 24px;
				&.dropdown-toggle {
					padding-right: 30px;
					background: $rd2;
					color: $wh;
				}
				&:hover {
					background: $rd;
					color: $wh;
				}
				.caret {
					border: 0;
					position: relative;
					display: none;
					&:before {
						@include transition(all 200ms ease-out);
						content: '\f107';
						font-family: 'FontAwesome';
						position: absolute;
						left: 8px;
						top: -13px;
						font-size: 20px;
						font-weight: 100;
						color: $dg;
					}
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		height: auto;
		color: $text-color;
		border-top: none;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		box-shadow: none;
		background-color: $megamenu-sub-bg-color;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		ul, ol {
			padding: 0;
		}
		ul.cat_all {
			display: flex;
			flex-wrap: wrap;
			a.level1 {
				padding: 12px 0 0 0;
				> span {
					text-align: center;
					font-size: 14px;
					text-transform: uppercase;
				}
				> img {
					margin-top: 15px;
					width: 160px;
				}
			}
		}
		li {
			padding: 0;
			line-height: 20px;
			list-style: none;
			&:hover {
				> a {
					background:$wh;
					color: $rd2;
					text-decoration: none;
				}
				}
			a {
				padding: 12px 20px;
				display: inline-block;
				width: 100%;
				color: $dg;
				font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 24px;
				&:hover {
					background:$wh;
					color: $rd2;
					text-decoration: none;
				}

			}
			> ul.children {
				> li {
					a {
						padding: 4px 12px;
						color: $dg;
						font-family: $f;
						text-transform: none;
						font-size: 14px;
					}
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
				> .dropdown-menu-inner {
					width: 50%;
					min-height: 600px;
					background: $gr;
				}
			}
			> a {
				.fa {
					color: $bk;
				}

			}
		}
		
	}
	.dropdown-submenu {
		position: inherit;
		.dropdown-menu {
			width: 417px;
			position: absolute;
			top:0!important;
			left: calc(100% - 15px)!important;
			right: -402px;
			padding: 5px 0!important;
			min-height: calc(100% - 2px);
		}
		&:hover {
			> .dropdown-menu {
				display: block;
			}
			> a {

				.fa {
					color: $bk;
				}
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}

.pav-megamenu .dropdown-menu li a {
			
			span {
					display: block;
					width: 80%;
					float: left;
					padding-left: 0px;
					height: 40px;
					line-height: 40px;
					img {
						margin-right: 10px;
					}
				}
				.caret {
						display: block; float: right;
						border: 0;
						margin-top: 7px;
						width: 24px;
						height: 24px;
						background: url("#{$image-theme-path}ic_arrow_.svg") repeat 0 0;
					}
}

.pav-megamenu .dropdown-menu .dropdown-menu li a {
		height: auto;
		span {
					display: block;
					width: 100%;
					float: left;
					padding-left: 0px;
					height: auto;
					line-height: normal;
					background:none;
				}
}
.pav-megamenu .navbar-nav .level2 ul li span {
	background: none!important;
}

.tree-menu {
	ul {
		li {
			padding-left: 0;
			a {
				font-family: $hf;
				font-weight: bold;
font-size: 18px;
line-height: 24px;
			}
			.accordion-heading .fa {
    			font-size: 14px;
    		color: $bk;
		}
		}
	   ul {
	   	padding-left: 10px;
	   	 li {
	   	 	padding: 5px 0;
	   	 }
	   }
	}
}