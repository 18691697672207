$dg: #3C3C3C;
$bk: $dg;
$black: $dg;
$wh: #fff;
$mg: #3f3f3f;
$lb: #82b4bd;
$tc: #ffcf06;
$bc: #7e8085;
$gr: #F0F1F3;
$gr2: #BABABA;
$gr3: #999;
$gr4: #DBDBDB;
$rd: #D82C28;
$rd2: #EE423E;
$gn: #25B92F;

$gbg1: #fafafa;
$gbg2: #f3f3f3;
$gbg3: #e1e1e1;

$f: 'Roboto', sans-serif;
$hf: 'Akrobat', sans-serif;
$fa: 'FontAwesome';

@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "fonts";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx";
@import "opencart/modules/simplebar.scss";

@import "app/custom";
@import "opencart/responsive";