.breadcrumb {
	> li {
		display: inline-block;
		font-family: $hf;
		font-size: 12px;
		text-transform: uppercase;
		+ li:before {
			content: "#{$breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 5px;
			color: $text-color;
		}
		&:first-child {
			font-size: 14px;
		}
	}
	> .active {
		font-weight: 600;
	}
}