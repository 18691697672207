$border-color: #ccc!default;
$brand-default: #222!default;

$bo-accordion-background:                        $brand-primary !default;
$bo-accordion-color:                             $brand-primary !default;

$bo-accordion-border-color:                      #eee !default;

$white : #FFFFFF !default;
$call-to-action-border-color: #999999 !default;


$icons-outline-color:                              #fff!default;
$icons-outline-bg:                                 transparent!default;
$icons-outline-border:                             rgba(255,255,255,0.2)!default;
$icons-outline-hover-color:                        #fff!default;		
$icons-outline-hover-bg:                           rgba(255,255,255,0.2)!default;
$icons-outline-hover-border:                       darken($icons-outline-hover-bg, 5%)!default;


$icons-inverse-color:                              #fff!default;
$icons-inverse-bg:                                 #d1d646!default;
$icons-inverse-border:                             darken($icons-inverse-bg, 5%)!default;
$icons-inverse-hover-color:                        #d1d646!default;	
$icons-inverse-hover-bg:                            transparent!default;
$icons-inverse-hover-border:                       $icons-inverse-bg!default;


$panel-blue-text:          $blue  !default;
$panel-blue-border:        $blue !default;
$panel-blue-heading-bg:    #fff !default;

$panel-orange-text:          $orange  !default;
$panel-orange-border:        $orange !default;
$panel-orange-heading-bg:    #fff !default;

$panel-pink-text:          $pink  !default;
$panel-pink-border:        transparent !default;
$panel-pink-heading-bg:    transparent !default;

$panel-cyan-text:          $cyan  !default;
$panel-cyan-border:        transparent !default;
$panel-cyan-heading-bg:    transparent !default;

$panel-red-text:          $red  !default;
$panel-red-border:        transparent !default;
$panel-red-heading-bg:    transparent !default;

$panel-green-text:          $green  !default;
$panel-green-border:        $green !default;
$panel-green-heading-bg:    transparent !default;