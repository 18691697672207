.buttons {
    @include clearfix;
    padding: 20px 0;
    .left {
        float: left;    }
    .right {
        float: right;
    }
}

.btn {
    border-radius: 3px;
    font-family: $hf;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
padding: 8px 16px;
height: auto;
}
.btn-primary {
    @include button-variant-outline($wh, $rd2, $rd2, $rd2, $wh, $rd2);
    @include transition (all 200ms ease 0s);
}
.btn-default {
    @include button-variant-outline($rd2, $wh, $rd2, $wh, $rd2, $rd2);
    @include transition (all 200ms ease 0s);
}

.checkbox, .radio{
    padding-left:20px;
    text-transform: none;
}
.btn-outline-inverse{
    @include button-variant-outline($gray-dark, $white, #f2f2f2, $white, $brand-success , #f2f2f2);
    border:none;
    border-right:1px solid #f2f2f2;
    &.last{
        border-right: none;
    }
} 
.btn-dark{
    @include button-variant-outline($text-color, $white, $border-color, $white, $blue , $blue);
    @include size(40px,34px);
    font-size: $font-size-base;
    padding:0 10px;
    line-height: 34px;
}
.btn-red{
    @include button-variant-outline($white, $theme-color, $theme-color, $white, $black , $blue);
    border-radius: 0;
    padding: 0;
    @include transition (all 200ms ease 0s);
}
.btn-outline-success{
    padding:15px 25px;
    line-height: 13px;
}
.btn-link {
  font-size:$font-size-base - 2;
  text-transform:lowercase;
  border-bottom:1px solid rgba(0,0,0,0.2);
  padding-bottom:2px;
}