@media (min-width: 1450px) {
	.cat_home {
		.image-left {
			width: 90%;
		}
		.image-right {
			top: 20%;
			width: 30%;
		}
	}
	#home_about {
		.col-md-6:nth-child(1) {
			width: 40%;
		}

		.title {

		}
	}
	#home_about .sub_title_block .sub_title {
		padding-left: 25px;
		padding-right: 60px;
	}
	#home_about .sub_title_block .sub_title:before {
		left: 25px;
	}
	.bpt {
		padding: 25px 0;
	}
	.tabs_product_module {
		margin-top: 80px;
	}
	.podpiska {
		margin-top: 40px;
	}
	.product-info .btn-credit {
		float: left;
	}
	.product-info .btn-credit.btn-rassrocka {
		margin-left: 15px;
	}
	.product-info .cheap_invoice {
		float: left;
		margin: 0 0 0 15px;

	}
	.product-info .cheap_invoice a {
		vertical-align: top;
	}
	.image-container {
		padding-right: 200px;
	}
	.tabs_product_module > .tab-content > .row {
		border-top: 1px solid $gr;
	}
	.inner_block {
		border-left: 0;
		border-top: 0;
	}
	.tabs_product_module .inner_block:nth-child(6n+1) {
		border-left: 1px solid $gr;
	}
	#products .inner_block:nth-child(6n+6) {
		border-right: 0;
	}

	#payment_methods .radio_custom .input-control {
		min-height: 50px;
	}

	.defhome .all_action a {
		padding: 30px 88px;
	}
	.product-markdown-view {
		.btn-credit {
			float: unset;
		}
		.btn-credit {
			&.btn-rassrocka {
				margin: 0;
			}
		}
		.cheap_invoice {
			margin: 0;
		}
	}
}

@media (min-width: 1025px) and (max-width: 1399px) {

	#products .inner_block:nth-child(4n+4) {
		border-right: 0;
	}
}

@media(max-width: 1449px) {
	.information-news-item {
		.news_column {
			flex: 0 30%;
		}
		#content {
			flex: 1 70%;
		}
	}
	#news_list .news_item {
		flex: 0 25%;
	}
}

@media (min-width: 992px) and (max-width: 1399px) {

	.tabs_product_module > .tab-content > .row {
		border-top: 1px solid $gr;
	}
	.inner_block {
		border-left: 0;
		border-top: 0;
	}
	.tabs_product_module .inner_block:nth-child(4n+1) {
		border-left: 1px solid $gr;
	}

	#payment_methods .radio_custom .input-control {
		min-height: 76px;
	}

}

@media (min-width: 992px) and (max-width: 1024px) {

	#products .inner_block:nth-child(3n+3) {
		border-right: 0;
	}
	.cat_home.cat_home2 .image-left img.hidden-xs {
		height: 340px;
	}
	.tabs_product_module.home_podarok .inner_block {
		border-bottom: 0;
		border-left: 0;
	}
	.tabs_product_module.home_podarok .inner_block:nth-child(2) {
		border-left: 1px solid $gr;
	}
}

@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}

	.modal-dialog {
		width: 500px;
	}

}

@media (max-width: $screen-md-max) {
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu {
		#bs-megamenu {
			margin-right: 7px;
		}
		.navbar-nav > li > a {
			padding: 24px 9px;
			font-size: 18px;
			&.dropdown-toggle {
				padding-right: 9px;
			}
			.caret::before {
				left: 3px;
			}
		}
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.product-markdown-view {
		.wrapper-credit-group {
			flex-direction: column;
		}
	}
	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 170px;
			margin-right: 5px;
			span {
				font-size: 8px;
				letter-spacing: 1px;
				left: 16px;
			}
		}
	}

	.defhome {
		.rev_slider {
			li {
				.slider_desc {
					position: absolute;
					left: 80px;
					top: 60px;
					width: 40%;
					.title {
						font-size: 44px;
						line-height: 48px;
						margin: 0 0 20px 0;
					}
					.text {
						font-size: 16px;
						line-height: 18px;
					}
				}
			}
		}
	}
	.bpt {
		padding: 15px 0;
	}
	.cat_home .image-left .opisanie {
		top: 15%;
	}
	.cat_home .image-right ul li a {
		margin: 0 0 10px 0;
		font-size: 16px;
		line-height: normal;
	}
	#payment_methods .radio_custom {
		min-height: 96px;
	}
	.page-product .tabs_product_module .title_module span {
		font-size: 30px;
		line-height: normal;
	}
	#news_list .news_item {
		flex: 0 calc(100% / 3);
	}
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
	.page-product #image-additional {
		position: absolute;
		left: -120px;
		padding-top: 150px;

		.owl-carousel {
			transform: rotate(90deg);
			width: 350px;
			margin-top: 0px;
		}
		.item {
			transform: rotate(-90deg);
		}
		.owl-carousel .owl-nav {
			display: flex;
			justify-content: space-between;
			position: absolute;
			width: 100%;
			top: calc(50% - 13px);
		}
		div.owl-carousel .owl-nav .owl-prev, div.owl-carousel .owl-nav .owl-next {
			font-size: 18px;
			top: unset;
			bottom: 15px;
			color: $bk;
		}
	}
	.common-home #sidebar-main {
		padding: 0px;
	}
	#widget_news {
		padding: 0 25px 0 15px;
	}
}

@media (max-width: 1024px) {

	.product-info .btn-credit.btn-rassrocka {
		margin: 10px 0 0 0;
		width: 100%;
		text-align: left;
	}
	.home_podarok.tabs_product_module .title_module span {
		margin: 15px 0;
		padding: 0 0 15px 0;
		br {
			display: none;
		}
	}
	.product-markdown-view {
		.btn-credit {
			&.btn-rassrocka {
				margin: 0;
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}

	#news_list .news_item {
		flex: 0 50%;
	}
	.information-news-item {
		.container-inside > .row {
			flex-wrap: wrap;
		}
		.news_column {
			flex: 100%;
		}
		#content {
			flex: 100%;
		}
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.footer-name {
			img {
				width: 300px;
			}
			span {
				display: block;
			}
		}
		.panel {
			margin-bottom: 15px;
		}
	}
	#footer {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.action > div {
				padding-right: 2px;
			}
		}
	}

	body .mfilter-free-container {
		top: 163px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		#mfilter-free-container-0 {
			padding: 0 5px !important;
			overflow: auto !important;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border-color: $theme-color;
			&:before {
				content: '\f0b0';
				font-family: 'Fontawesome';
				color: $white;
				font-size: 24px;
				line-height: 23px;
				padding: 3px;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.h1_container h1 {
		padding: 15px;
		font-size: 30px;
	}

	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 10px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.pagescarousel .pc_wrapper {
		flex-wrap: wrap;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 100%;
			}
			&.pc_image {
				order: 1;
				padding: 0;
			}
			&.pc_text {
				order: 2;
			}
		}
	}
	.func_list {
		flex-wrap: wrap;
		& > div {
			width: 100%;
		}
		img {
			max-width: 100%;
			max-height: unset;
		}
	}

	.defhome {

		.rev_slider {
			li {
				.slider_desc {
					width: 80%;
					left: 20px;
					top: 20px;
					.title {
						font-size: 34px;
						line-height: 38px;
						margin: 0 0 20px 0;
					}
				}

			}
		}
	}

	.cat_home .image-left .opisanie {
		top: auto;
		bottom: 15%;
		.title {
			font-size: 36px;
			line-height: 44px;
		}
	}
	.cat_home .image-right {
		width: 50%;
		right: 10px;
	}
	.cat_home .image-right ul li a {
		margin: 5px 0 15px 0;
		font-size: 18px;
		line-height: normal;
	}
	#home_about .title {
		padding: 48px 18px 0 18px;
		font-size: 30px;
		line-height: 36px;
	}
	#home_about .sub_title_block {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	#home_about .link {
		margin: 25px 0 50px 0;
		padding: 0 18px;
	}
	.ft-phone {
		display: block;
	}
	.order {
		display: flex;
	}
	.order-sm-1 {
		order: 1;
		position: relative;
	}
	.order-sm-2 {
		order: 2;
	}
	.cat-items {
		padding: 50px 0 0 30px;
		height: 225px;
	}
	.cat-items .title {
		font-size: 30px;
		line-height: 36px;
	}
	.cat-items .count {
		font-size: 24px;
		line-height: 28px;
	}
	#payment_methods .radio_custom {
		width: 100%;
		height: auto;
		min-height: auto;
	}
	.media-left, .media-right, .media-body {
		display: block;
	}
	.header-top {
		height: auto;
	}
	.header-top .topcontact_block {
		float: none;
		padding: 7px 0;
	}
	.header-top .topcontacts.worktime {
		margin: 0 0 5px 0;
		text-align: center;
		font-size: 12px;
		line-height: 16px;
	}
	.header-top .topcontacts.phone {
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		span {
			display: inline-block;
		}
	}
	#searchtop {
		margin: 10px 0 0 0;
	}

	.logo_mob {
		padding: 15px 0 0 0;
		text-align: center;
		display: block;
	}
	.headercart {
		margin-right: 20px;
	}
	#cart {
		height: auto;

	}
	#searchtop input.form-control {
		border-bottom: 2px solid $bk;
	}
	.defhome .all_action a {
		padding: 15px 30px;
	}
	.canvas-menu {
		background: $rd2;
		padding: 14px 20px;
		margin: 0 -5px;
		color: $wh;
	}

	.buy_products .cart-top-padding {
		padding-top: 0px;
	}
	.defhome .tp-bullets {
		bottom: -35px !important;
	}
	.tabs_product_module.home_podarok .inner_block {
		border-bottom: 0;
		border-left: 0;
	}
	.tabs_product_module.home_podarok .inner_block:nth-child(2) {
		border-left: 1px solid $gr;
	}

	.ft-addr > span {
		display: block;
	}

	.sidebar-offcanvas .offcanvas-heading.panel-heading {
		display: none;
	}
	.sidebar-offcanvas .panel-heading {
		padding: 0 20px;
	}

	.page-product {
		padding-bottom: 104px;
	}
	.product-markdown-view {
		.wrapper-credit-group {
			flex-direction: row;

			.btn-rassrocka {
				width: auto;
			}
		}
	}
}

@media (max-width: $screen-sm) {

	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

// 767 px
@media (max-width: $screen-xs-max) {
	.holiday_works {
		position: unset;
		width: 100%;
		text-align: center;
	}
	.fancybox-skin {
		padding: 24px 16px !important;
	}
	.holiday_table {
		.hw_col {
			font-size: 14px !important;
			line-height: 14px !important;
		}
	}
	#header-layout {
		.header-bottom {
			.product-compare-header {
				img {
					height: 26px;
					width: 26px;
				}
			}
			.headercart {
				#cart {
					img {
						width: 26px;
						height: 23px;
					}
				}
			}
		}
	}
	h1 {
		font-size: 20px !important;
		line-height: 24px;
	}
	.product-info .prod_info .btn-compare {
		margin-left: 0;
	}
	.prod_info .col-xs-12 {
		margin-bottom: 10px;
	}
	.product-info .product_right_info {
		margin: 0 -15px 25px -15px;
	}
	.tabs .tabs__caption li {
		padding: 15px 25px;
		font-size: 16px;
		line-height: 20px;
		display: inline-block;
		text-align: center;
		float: none;
		width: 100%;
	}
	.tabs .tabs__content {
		padding: 0 10px 10px 10px;
	}
	.param_list .double, .param_list .single {
		background: none;
	}
	.param_list .double .param_k, .param_list .single .param_k {
		width: 70%;
		display: inline;
		float: left;
	}
	.param_list .double .param_v, .param_list .single .param_v {
		width: 30%;
		min-width: auto;
	}
	.storage_widget {
		padding: 0;
		background: none;
		table {
			display: block;
			tr {
				display: block;
				td {
					display: block;
					text-align: left !important;
				}
			}
		}
	}
	#storage_address {
		padding: 10px 0 10px 50px !important;
		background: url(../image/reserv_mob.svg) no-repeat left 5px;
		display: block;
		margin-bottom: 10px;
		min-height: 30px;
	}
	.tabs_product_module .title_module span {
		font-size: 20px;
		line-height: 24px;
		padding: 10px 0;
		margin-bottom: 10px;
		border-bottom: 4px solid $rd2;
		br {
			display: none;
		}
	}
	#footer {
		margin-top: 40px;
	}
	.buy_products .list_product_cart .row .btn-delete {
		margin: 0;
	}
	.buy_products .list_product_cart .row .product-quantity {
		margin: 15px 0 15px 0;
	}
	.bottom_cart {
		margin-bottom: 0;
		padding: 15px;
	}
	#buy_form_wrapper #promo_kod {
		position: relative;
		bottom: auto;
		background: $gr;
	}
	#buy_form_wrapper #promo_kod #coupon_form .coupon_button input {
		width: 100%;
		padding: 1px 0;
		height: auto;
	}
	#buy_form_wrapper #promo_kod #coupon_form .coupon_button .form-group {
		float: left;
		margin: 0;
	}
	#buy_form_wrapper #promo_kod #coupon_form .coupon_button #button-coupon {
		float: left;
	}
	#client-form, #shipping_methods, #payment_methods, #comment_block, #buttons-cart {
		padding: 10px 10px;
		margin: 0 -30px 15px -30px;
	}
	#shipping_methods, #payment_methods, #comment_block {
		margin-top: 48px;
	}
	#input-comment {
		width: calc(100% - 30px);
		margin: 0 15px;
	}
	#shipping_methods .tabs .tabs__caption li {
		width: 100%;
		padding: 5px 10px;
		margin-bottom: 5px;
	}
	.common-home .main-column {
		padding: 0;
	}
	.defhome .all_action a {
		padding: 5px;
	}

	.cat_home .image-left {
		width: 100%;
	}
	.cat_home .image-right {
		position: relative;
		width: 100%;
		right: auto;
		top: auto;
	}
	.cat_home .image-right:after {
		display: none;
	}
	.cat_home .image-left .opisanie .title {
		font-size: 24px;
		line-height: 28px;
	}
	.cat_home .image-right ul {
		padding: 0;
	}
	.cat_home .image-right .more {
		margin-left: 0;
	}
	#home_about .title {
		font-size: 20px;
		line-height: 24px;
	}
	#home_about .sub_title_block .sub_title {
		font-size: 16px;
		line-height: 20px;
	}
	#widget_news {
		padding: 0 15px;
	}
	#subscribe_block .title, #widget_news .title {
		font-size: 20px;
		line-height: 24px;
	}
	#subscribe_block #subscribe_type {
		margin-top: 10px;
	}
	#subscribe_block #subscribe_type .form-group {
		width: calc(100% - 40px);
		float: left;
	}
	#subscribe_block .checkbox_custom .input-control {
		padding-left: 30px;
	}
	#footer .payment {
		overflow: hidden;
		margin-bottom: 15px;
	}
	#footer .copyright {
		padding-top: 0;
	}
	.product-compare-header {
		margin: 0 10px 0 0;
	}
	.product-compare-header p#compare-total, #cart .wrap-cart .badge {
		margin: 2px 3px 0 5px;
	}
	.headercart {
		margin-right: 3px;
	}
	#top_right {
		margin: 0 -15px;
		height: auto;
	}
	#top_right > div {
		padding-top: 10px;
	}
	.canvas-menu {
		padding: 5px 15px;
	}
	.header-bottom {
		height: auto;
		position: relative;
	}
	#searchtop {
		margin-top: 5px;
	}
	.rev_slider {
		height: 350px !important;
	}
	.tabs_product_module {
		margin-top: 0;
	}
	.home_featured, .home_newest, .page-product .tabs_product_module {
		margin-top: 48px;
	}
	#home_about {
		margin-bottom: 48px;
	}
	.bpt {
		height: 48px;
		padding: 0;
	}
	.logo_mob {
		margin: 0 -15px;
	}
	.relative {
		position: inherit;
	}
	#searchtop {
		position: inherit;
		border: 0;
		input.form-control {
			opacity: 0;
			visibility: hidden;
			background: $wh;
			width: 100%;
			position: absolute;
			right: 0;
			top: 100%;
			background: $wh;
			padding: 10px;
			z-index: 2;
			@include transition(0.2s all);
			&.srch {
				opacity: 1;
				visibility: visible;
				& ~ .srchwall {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.input-group-btn {
			z-index: 3;
		}
		.btn-search {
			&:hover {
				color: #acacac;
			}
		}
	}
	.logo_mob {
		padding-top: 10px;
	}
	#cart .cart-outer {
		display: none !important;
	}
	.list_product_cart > .row {
		border: 0 !important;
		border-bottom: 1px solid $gr !important;
		overflow: hidden;

	}
	.bottom_cart {
		margin: 0;
	}
	.home_podarok {
		margin-top: 25px;
	}
	.breadcrumbs {
		padding: 10px 0 0 0;
	}
	.storage_widget table {
		margin-top: 0;
	}
	.storage_widget table tr td:last-child span {
		margin-left: 0;
	}
	#vigoda {
		margin: 0 -15px 48px -15px;
	}
	.tabs {
		.owl-carousel {
			.owl-prev, .owl-next {
				top: calc(50% - 23px);
				background: none !important;
				width: 33px;
			}
			.owl-prev {
				left: -20px;
			}
			.owl-next {
				right: -10px;
			}
		}
	}
	.tabs_product_module .inner_block:nth-child(2) {
		border-left: 1px solid $gr;
	}
	#news_list {
		display: block;
		.news_item {
			padding-right: 0;
		}
	}
	.information-news {
		h1 {
			padding: 15px 0 20px 0;
		}
	}
	.product-markdown-view {
		.cheaper-item {
			margin: 0;
		}
	}
}

@media (min-width: 425px) and (max-width: 767px) {
	.col-ws-6 {
		width: 50%;
		float: left;
	}
	.product-block .cart {
		margin-right: -15px;
	}
	.logo_mob .hidden-xs {
		margin-top: 5px;
	}
	.logo_mob .hidden-sm {
		display: none !important;
	}
}

@media (max-width: 637px) {
	.success {
		&.product-info {
			width: 300px;
			margin-left: -150px;
			padding: 15px 15px 15px 15px;
			h2 {
				margin-bottom: 15px;
			}
			.success_button {
				padding: 6px;
				font-size: 13px;
			}
			.img_product {
				width: 100%;
				text-align: center;
			}
			.info {
				width: 100%;
				.name {
					margin-bottom: 10px;
				}
				.price {
					margin-bottom: 10px;
				}
				.go_cart, .go_shop {
					width: 100%;
					padding-bottom: 5px;
					a {
						width: 100%;
					}
				}
			}
		}
	}
	.defhome .tp-bullets {
		bottom: -25px !important;
	}

	.defhome .all_action a {
		font-size: 16px;
		line-height: 20px;
		padding: 10px;
	}
	.more_products {
		padding: 15px 0;
	}
	#widget_news .link {
		margin-left: 0;
		margin-right: 0;
	}
	.footer-center {
		padding-top: 0;
	}
	.footer-center .box .box-heading {
		margin: 32px 0 16px 0;
	}
	.refine-search {
		margin-bottom: 14px;
		.list-inline {
			margin: 0 -15px;
		}
	}
	.product-filter {
		margin: 0 -15px 32px -15px;
		.filter-right {
			border-bottom: 1px solid #F0F1F3;
		}
	}
	#products {
		border: 0;
	}
	.paging .pagination > li {
		height: 40px;
		line-height: 40px;
		width: 40px;
		span {
			line-height: 40px;
		}
		a {
			line-height: 40px;
		}
	}
	.page-product .breadcrumbs .breadcrumb > li:last-child {
		display: none;
	}
	#kupit_mob {
		padding: 17px 16px 15px 16px;
		button {
			font-size: 16px;
			line-height: 24px;
			padding: 8px 0 7px 0;
			.price-old {
				font-size: 14px;
			}
		}
	}
	.tabs_product_module .title_module span, .page-product .tabs_product_module .title_module span {
		font-size: 20px;
	}
	.container-inside {
		margin: 0;
	}
	.list_product_cart {
		padding: 0;
	}
	.bottom_cart, #buy_form_wrapper #promo_kod {
		margin: 0 -15px;
	}
	.cart_title2 {
		font-size: 16px;
		line-height: 20px;
		margin: 30px 0 32px 0 !important;
	}
	#shipping_methods .tabs .tabs__caption li {
		text-align: left;
		margin-bottom: 16px;
		padding: 0;
	}
	.cart_title3 {
		font-size: 16px;
		line-height: 20px;
	}
	.radio_custom .input-control, .radio_custom .list_of_points {
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
	}
	#shipping_methods .radio_custom {
		padding: 18px 16px
	}
	#shipping_methods .tabs .tabs__content {
		padding-bottom: 10px;
	}
	#comment_block .form-group {
		margin: 0 -15px;
	}
	.checkbox_custom .input-control {
		padding-top: 0;
	}
	.page-product {
		padding-bottom: 72px;
	}
	h2 {
		font-size: 20px;
		line-height: 24px;
	}
	.cat-items {
		margin: 0 0 14px 0;
	}
	#footer {
		background: url("#{$image-theme-path}footer2.jpg") center center no-repeat;
	}
}

@media (max-width: 570px) {
	#powered > .container {
		padding: 0;
	}

}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.header-top .headercart {
		flex: 1 0 120px;
	}
	.fancy_holiday_works {
		width: 90% !important;
		.fancybox-inner {
			width: 100% !important;
		}
	}
	.holiday_table {
		width: 100%;
		td {
			font-size: 13px;
		}
	}

}

@media (max-width: 424px) {
	.tabs_product_module .title_module {
		height: auto !important;
		padding-top: 7px;
		padding-bottom: 7px;
	}
	.logo_mob .hidden-xs {
		margin-top: 5px;
		margin-left: 3px;
		display: block !important;
	}
	.logo_mob .hidden-sm {
		display: none !important;
	}
	.product-markdown-view {
		.wrapper-credit-group, .cart {
			flex-direction: column;
		}
		.product-meta {
			.cart {
				button {
					width:100%;
				}
			}
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.header-bottom .logo-container span {
		font-size: 2.5vw;
		top: 0;
		left: calc(19px + 100vw / 320);
	}
	.product-info .tabs {
		margin-left: -15px;
		margin-right: -15px;
	}
}

@media (max-width: $screen-phone) {
	.product-grid {
		.product-block {
			width: 100%;
		}
	}

}
