@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-MediumItalic.eot');
    src: url('/fonts/roboto/subset-Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-MediumItalic.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-BoldItalic.eot');
    src: url('/fonts/roboto/subset-Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-BoldItalic.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Italic.eot');
    src: url('/fonts/roboto/subset-Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Italic.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Black.eot');
    src: url('/fonts/roboto/subset-Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Black.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Light.eot');
    src: url('/fonts/roboto/subset-Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Light.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Regular.eot');
    src: url('/fonts/roboto/subset-Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Regular.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Medium.eot');
    src: url('/fonts/roboto/subset-Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Medium.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-ThinItalic.eot');
    src: url('/fonts/roboto/subset-Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-ThinItalic.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Thin.eot');
    src: url('/fonts/roboto/subset-Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Thin.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-BlackItalic.eot');
    src: url('/fonts/roboto/subset-Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-BlackItalic.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-LightItalic.eot');
    src: url('/fonts/roboto/subset-Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-LightItalic.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/subset-Roboto-Bold.eot');
    src: url('/fonts/roboto/subset-Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/roboto/subset-Roboto-Bold.woff2') format('woff2'),
        url('/fonts/roboto/subset-Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-Light.eot');
    src: url('/fonts/akrobat/subset-Akrobat-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-Light.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-ExtraLight.eot');
    src: url('/fonts/akrobat/subset-Akrobat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-ExtraLight.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-Bold.eot');
    src: url('/fonts/akrobat/subset-Akrobat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-Bold.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-Regular.eot');
    src: url('/fonts/akrobat/subset-Akrobat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-Regular.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-ExtraBold.eot');
    src: url('/fonts/akrobat/subset-Akrobat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-ExtraBold.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-Black.eot');
    src: url('/fonts/akrobat/subset-Akrobat-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-Black.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-SemiBold.eot');
    src: url('/fonts/akrobat/subset-Akrobat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-SemiBold.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/fonts/akrobat/subset-Akrobat-Thin.eot');
    src: url('/fonts/akrobat/subset-Akrobat-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/akrobat/subset-Akrobat-Thin.woff2') format('woff2'),
        url('/fonts/akrobat/subset-Akrobat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
