.autosearch-wrapper.input-group {
	margin-top: 17px;
}

#content {
	.sphinxsearch {
		left: 15px;
	}
}

.sphinxsearch {
	width: 100%;
	right: 0;
	left: auto;
	position: absolute;
	top: 100%;
	margin-top: 1px;
	border: none;
	z-index: 99999;
	background: $wh;
	border-radius: 3px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	& > a {
		transition: 0.2s all;
		display: inline-block;
		width: 100%;
		padding-bottom: 5px;
		cursor: pointer;
		line-height: 1;
		position: relative;
		border-bottom: 1px #ebebeb solid;
		&:first-child {
			border-top: none;
		}
		&:last-child {
			border-bottom: none;
		}
		img {
			float: left;
			margin: 0 10px 0 0;
			padding: 5px;
		}
	}
	.categories, .products {
		span {
			display: block;
			padding: 10px 0;
			font-size: 14px;
			text-align: center;
			background: #eee;
		}
	}
	.categories {
		a {
			text-transform: uppercase;
			text-indent: 10px;
		}
	}
}

.product_sp {
	width: 100%;
	font-size: 16px;
	margin: 0;
	font-family: $hf;
	position: relative;
	top: 6px;
	padding-bottom: 30px;
}

.price_sp {
	width: calc(100% - 95px);
	font-size: 16px;
	position: absolute;
	bottom: 7px;
	left: 80px;
	font-weight: 600;
	.old {
		font-size: 14px;
		text-decoration: line-through;
		margin-right: 7px;
		color: #777;
	}
}

.sphinx-viewall {
	padding: 6px 25px;
	margin: 10px 0;
	display: inline-block;
	text-align: center;
	width: 100%;
	&:hover {
		background: $white;
	}
	#view-all {
		font-family: $hf;
		padding: 0 25px;
		display: inline-block;
		font-size: 18px;
		height: 36px;
		text-transform: uppercase;
		font-weight: 600;
		background: #EE423E;
		color: $wh;
		line-height: 36px;
		text-decoration: none;
		&:hover {
			background: $black;
			color: $wh;
		}
	}
	&-no-res {
		font-weight: bold;
		text-align: center;
		height: 30px;
		color: $black;
		width: 100%;
		line-height: 30px;
	}
}

@media (max-width: 991px) {
	.sphinxsearch {
		.product_sp {
			padding: 10px 10px 20px 10px;
		}
	}
}
@media (max-width: 767px) {
	.sphinxsearch {
		top: 91px;
	}
}